<template>
  <div id="aftersale">
    <div class="EmployeesAccountTitle flexAndCenter">
      <div class="fontSize20 color333 fontWeightBold">{{ $fanyi("售后") }}</div>
    </div>
    <div class="content">
      <!-- 搜索 -->
      <div class="searchbox">
        <div class="orderKeywordsContainer flexAndCenter">
          <el-input v-model.trim="form.porder_sn" :placeholder="$fanyi('请输入关键词')" style="width: 240px;"/>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter" style="margin-left:26px">
          <div @click="getData(form)">{{ $fanyi("搜索") }}</div>
          <div @click="resetOrder">{{ $fanyi("重置") }}</div>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="tablebox">
        <div>
          <div v-for="(item,index) in tableData" :key="index" class="tableItem">
            <div class="Aftersalesordernumber flex">
              <p> {{ $fanyi("售后单号") }} : </p>
              <p> {{ item.after_sale_sn }}</p>
            </div>
            <div class="Dispatchnumber flex">
              <p> {{ $fanyi("配送单号") }} :</p>
              <p>
                <router-link :to="{ path: '/deliveryDetails', query: { pOrder_sn: item.porder_sn } }"
                             class="colorB4272B cursorPointer" style="text-decoration: underline;" target="_blank">
                  {{ item.porder_sn }}
                </router-link>
              </p>
            </div>
            <div class="Amountofindemnity flex">
              <p> {{ $fanyi('赔款金额') }} :</p>
              <p><span>{{ item.totalRefundToUserAmount }} {{ $fanyi('円') }} </span></p>
            </div>
            <div class="Aftersalescompletiontime flex">
              <p>{{ $fanyi('售后完成时间') }} :</p>
              <p><span>{{ item.complete_at }}</span></p>
            </div>
            <router-link
                :to="{ path: '/aftersaledetails', query: { after_sale_sn: item.after_sale_sn,porder_sn: item.porder_sn } }"
                class="Viewdetails cursorPointer" target="_blank">
              {{ $fanyi('查看详情') }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="flexAndCenter pagination" style="padding: 30px 0;justify-content: center">
        <el-pagination :current-page="form.page" :page-size="form.pageSize" :page-sizes="[10, 20, 30, 40]"
                       :total="total"
                       layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        porder_sn: "",// 订单号
        page: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: []
    }
  },
  created() {
    this.getData();
  },
  methods: {
    // 重置
    resetOrder() {
      this.form = {
        porder_sn: "",// 订单号
        page: 1,
        pageSize: 10,
      }
    },   //设置当前多少页
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData()
    },
    //设置每页多少条
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.getData()
    },
    // 获取数据
    async getData() {
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const res = await this.$api.aftersalelist(this.form)
      this.loading.close();
      if (res.code != 0) {
        this.$message.error(res.msg);
        return
      }
      this.total = res.data.total
      this.form.page = res.data.currentPage
      this.form.pageSize = Number(res.data.pageSize)
      this.tableData = res.data.data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

#aftersale {
  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;
    border-bottom: 1px solid #DFDFDF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 26px;
    background-color: #fff;

    .searchbox {
      background-color: #fff;
      height: 50px;
      display: flex;
      align-items: center;

      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DFDFDF;

      .orderKeywordsContainer {
        /deep/ .el-input {
          height: 30px;
        }

        /deep/ .el-input__inner {
          width: 240px;
          height: 30px;
          background: #FFFFFF;
          border: 1px solid #DFDFDF;
          border-radius: 6px;
        }
      }

      .orderScreenBtnListContainer {
        div {
          width: 76px;
          height: 30px;
          border-radius: 6px;
          cursor: pointer;
          text-align: center;
          font-size: 12px;
          line-height: 30px;
        }

        div:first-child {
          color: #fff;
          background: #B4272B;
          margin-right: 10px;
        }

        div:last-child {
          border: 1px solid #B4272B;
          color: #B4272B;
        }
      }
    }

    .tablebox {
      width: 100%;
      min-height: 280px;

      .tableItem {
        display: flex;
        align-items: center;
        height: 80px;
        border-bottom: 1px solid #DFDFDF;
        justify-content: space-between;
      }

      .Aftersalesordernumber {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        width: 250px
      }

      .Dispatchnumber {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: 300px;

        span {
          color: #B4272B;

          &:hover {
            cursor: pointer;
          }
        }
      }


      .Amountofindemnity {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: 150px
      }

      .Aftersalescompletiontime {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: 240px;
      }

      .Viewdetails {
        width: 88px;
        height: 30px;
        background: #B4272B;
        border-radius: 6px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .subleveltable {
        img {
          width: 60px;
          height: 60px;
        }

        .Commodityattribute {
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align-last: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .Purchasequantity {
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
          }
        }

        .option,
        .tagbox {
          overflow-y: auto;

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            text-align: left;
          }
        }

        .tag {
          overflow: auto;

          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }


      }
    }
  }

  .pagination {
    background-color: #fff;

    /deep/ .el-pagination .el-select .el-input {
      width: 110px;
    }
  }
}
</style>